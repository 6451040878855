






































































import { Component, Vue } from "vue-property-decorator";
import PageFrame from "@/components/elements/PageFrame.vue";
import ManualForm from "@/components/fragments/ManualForm.vue";
import axios from "axios";
import Modal from "@/components/elements/Modal.vue";
import RegisterDialog from "@/components/dialogs/RegisterDialog.vue";
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
import ValidatorOverlay from "@/components/elements/ValidatorOverlay.vue";
import SwitchButton from "@/components/elements/SwitchButton.vue";
import { APIURL,KEYVALUESTORE,CARDSTORE } from "../../store/globals";

/**
 * The entry type for a template
 */
type TemplateEntry = {
  id : number;
  name : string;
}

/**
 * Renders the home page
 * 
 * Events:
 * - loadTemplates = this.$root.$emit('loadTemplates')
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    RegisterDialog,
    Modal,
    ManualForm,
    PageFrame,
    ValidatorOverlay,
    SwitchButton,
    InfoDialog
  }
})
export default class Home extends Vue {
  /**
   * Returns the visibility state of the register dialog
   * 
   * @var isRegisterOpen
   * @type boolean
   */
  private isRegisterOpen = false;

  /**
   * Returns the visibility state of the info dialog
   * 
   * @var isInfoOpen
   * @type boolean
   */
  private isInfoOpen = false;

  /**
   * Returns a list of TemplateEntry elements
   * 
   * @var templateList
   * @type Array<TemplateEntry>
   */
  private templateList : Array<TemplateEntry> = [];

  /**
   * Returns the maximal amounts of template entries
   * 
   * @var hitsCounter
   * @type number
   */
  private hitsCounter = 0;

  /**
   * Returns the current loaded page
   * 
   * @var currentPage
   * @type number
   */
  private currentPage = 0;

  /**
   * Returns the loading state of the templates for new loading
   * 
   * @var isLoading
   * @type boolean
   */
  private isLoading = false;

  /**
   * Returns the width for the date column
   * 
   * This is needed to align the columns when a scrollbar appears
   * 
   * @var dateWidth
   * @type string
   */
  private dateWidth = '100px';

  private mode = 0;

  /**
   * Gets called before mounting
   * 
   * @returns void
   */
  beforeMount() {

    this.$root.$off('loadTemplates');
    this.$root.$on('loadTemplates', function(this:Home) {
      this.currentPage = 0;
      this.loadTemplates(this.currentPage);
    }.bind(this));
    this.$root.$emit('loadTemplates');

    // change view mode and reset form
    setTimeout(function(this:Home) {
      this.$root.$emit('reset-form',false);
    }.bind(this),0);

    if(!window.infoWasShown) {
      this.isInfoOpen = true;
      window.infoWasShown = true;
    }

  }

  /**
   * Loads the templates based on a page index
   * 
   * @param number pageIndex The page index
   * @returns void
   */
  async loadTemplates(pageIndex : number) {
    if (this.$store.state.session !== "") {
      this.isLoading = true;
      const res = await axios
        .post(APIURL + "/xrechnung/templates", {
          sid: this.$store.state.session,
          page : pageIndex
        }),
        data = res.data;
        console.log(data);

      this.isLoading = false;

      // if session is invalid
      if(data=='') {
        this.templateList = [];
        return;
      }

      if(pageIndex == 0) {
        this.templateList = data.rows;
        this.hitsCounter = data.hits;
      } else {
        for(let i = 0; i < data.rows.length;++i) {
          this.templateList.push(data.rows[i]);
        }
        this.hitsCounter -= 5;
      }

      if(this.templateList.length > 5) {
        this.dateWidth = '80px';
      } else {
        this.dateWidth = '100px';
      }
      
    }
  }

  /**
   * Resets the form
   * 
   * @return void
   */
  resetForm() {
    this.$root.$emit('reset-form',true);
  }

  /**
   * Opens the register dialog
   * 
   * @return void
   */
  openRegister() {
    this.isRegisterOpen = true;
  }

  /**
   * Closes the register dialog
   * 
   * @return void
   */
  closeRegister() {
    this.isRegisterOpen = false;
  }

  /**
   * Closes the info dialog
   * 
   * @return void
   */
  closeInfo() {
    this.isInfoOpen = false;
  }

  /**
   * Deletes a template
   * 
   * @param number id The template id
   * @returns void
   */
  deleteTemplate(id: number) {
    (this.$refs.modal as Modal).Open({
      title: "Vorlage löschen",
      message: "Möchten Sie die Vorlage wirklich löschen?",
      confirmButtonText : 'Ja',
      type: "yesno",
      callback: async function(this:Home, pressedyes: boolean) {
        if(!pressedyes) return;
            const res = await axios
            .post(APIURL + "/xrechnung/templates/delete", {
              sid: this.$store.state.session,
              id : id
            }),
            data = res.data;
        if(data=='1') {
          (this.$refs.modal as Modal).Open({
            title: "Erfolg!",
            message: "Die Vorlage wurde erfolgreich gelöscht.",
            confirmButtonText : 'OK',
            type: "confirm",
            callback: function(this:Home) {
              this.$root.$emit('loadTemplates');
            }.bind(this)
          });
        } else {
          (this.$refs.modal as Modal).Open({
            title: "Achtung!",
            message: "Die Vorlage konnte nicht gelöscht werden.",
            confirmButtonText : 'OK',
            type: "confirm",
            callback : () => {1;}
          });
        }
      }.bind(this)
    });
  }

  /**
   * Selects a template and fills out the form
   * 
   * @param number id The template id
   * @returns void
   */
  selectTemplate(id: number) {
    console.log("test", id);
    axios
      .post(APIURL + "/xrechnung/template/" + id, {
        sid: this.$store.state.session
      })
      .then(res => {
        for (const [key, value] of Object.entries(res.data.SELLER)) {
          this.$store.dispatch("updateSeller", { key, value });
        }
        for (const [key, value] of Object.entries(res.data.BUYER)) {
          this.$store.dispatch("updateBuyer", { key, value });
        }
        for (const [key, value] of Object.entries(res.data.HEADER)) {
          this.$store.dispatch("updateHeader", { key, value });
        }

        this.$store.dispatch("updateHeader", { key: "BT-106", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-109", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-110", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-112", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-108", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-107", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-113", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-115", value: 0 });

        (this.$refs.modal as Modal).Open({
          title: "Vorlage geladen",
          message: "Die Vorlage wurde erfolgreich dupliziert.",
          confirmButtonText : 'OK',
          type: "confirm",
          callback: function(this:Home) {
            this.$root.$emit('closeCardsAndFocus',true);
          }.bind(this)
        });
      });
  }

  /**
   * Selects a template and fills out completely the form
   * 
   * @param number id The template id
   * @returns void
   */
  fullSelectTemplate(id: number) {
    console.log("load fullSelectTemplate", id);
    axios
      .post(APIURL + "/xrechnung/template/" + id, {
        sid: this.$store.state.session
      })
      .then(res => {
        for (const [key, value] of Object.entries(res.data.SELLER)) {
          this.$store.dispatch("updateSeller", { key, value });
        }
        for (const [key, value] of Object.entries(res.data.BUYER)) {
          this.$store.dispatch("updateBuyer", { key, value });
        }
        for (const [key, value] of Object.entries(res.data.HEADER)) {
          this.$store.dispatch("updateHeader", { key, value });
        }

        this.$store.dispatch("updateHeader", { key: "BT-106", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-109", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-110", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-112", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-108", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-107", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-113", value: 0 });
        this.$store.dispatch("updateHeader", { key: "BT-115", value: 0 });

        this.$store.state.data.ITEM_DETAILS = [];
        let i = 0,
            entry;
        const len = res.data.ITEM_DETAILS.length;
        for(i; i < len; ++i) {
          entry = res.data.ITEM_DETAILS[i];
          this.$store.dispatch('addPosition');
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-155', value: entry['BT-155'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-128', value: entry['BT-128'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-153', value: entry['BT-153'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-129', value: entry['BT-129'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-148', value: entry['BT-148'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-156', value: entry['BT-156'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-127', value: entry['BT-127'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-149', value: entry['BT-149'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-146', value: entry['BT-146'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-151', value: entry['BT-151'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-152', value: entry['BT-152'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-131', value: entry['BT-131'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-134', value: entry['BT-134'] });
          this.$store.dispatch('updatePosition', { id: i, key: 'BT-135', value: entry['BT-135'] });
        }

        this.$store.state.data.ATTACH = [];
        let j = 0,
            entryAttach : any;
        const len2 = res.data.ATTACH.length;
        for(j; j < len2; ++j) {
          entryAttach = res.data.ATTACH[j];
          this.$store.dispatch('addAttachment');
          setTimeout(function(this:{self:Home,j:number,entryAttach:any}) {
            this.self.$store.dispatch('updateAttachment', { id: this.j, key: 'BT-122', value: this.entryAttach['BT-122'] });
            this.self.$store.dispatch('updateAttachment', { id: this.j, key: 'BT-123', value: this.entryAttach['BT-123'] });
          }.bind({self: this, j : j, entryAttach : entryAttach}),300);
        }

        this.$store.state.data.ALLOWANCE_CHARGE = [];
        let k = 0,
            entryAllowCharge : any;
        const len3 = res.data.ALLOWANCE_CHARGE.length;
        for(k; k < len3; ++k) {
          entryAllowCharge = res.data.ALLOWANCE_CHARGE[k];
          this.$store.dispatch('addAllowance');
          setTimeout(function(this:{self:Home,k:number,entryAllowCharge:any}) {
            this.self.$store.dispatch('updateAllowance', { id: this.k, key: 'BT-XX', value: this.entryAllowCharge['BT-XX'] });
            this.self.$store.dispatch('updateAllowance', { id: this.k, key: 'BT-92', value: this.entryAllowCharge['BT-92'] });
            this.self.$store.dispatch('updateAllowance', { id: this.k, key: 'BT-97', value: this.entryAllowCharge['BT-97'] });
            this.self.$store.dispatch('updateAllowance', { id: this.k, key: 'BT-95', value: this.entryAllowCharge['BT-95'] });
            this.self.$store.dispatch('updateAllowance', { id: this.k, key: 'BT-96', value: this.entryAllowCharge['BT-96'] });
          }.bind({self: this, k : k, entryAllowCharge : entryAllowCharge}),300);
        }

        (this.$refs.modal as Modal).Open({
          title: "Vorlage geladen",
          message: "Die Vorlage wurde erfolgreich für eine Rechnungskorrektur geladen.<br><i>Anlagen müssen neu ausgewählt werden.</i>",
          confirmButtonText : 'OK',
          type: "confirm",
          callback: function(this:Home) {
            this.$root.$emit('closeCardsAndFocus',true);
          }.bind(this)
        });
      });
  }

}
